<mat-card class="api-key-table mat-elevation-z2">
  <table mat-table class="table-api-keys" [dataSource]="keys">
    <ng-container *matNoDataRow>
      <tr class="mat-row row--no-data">
        <td class="mat-cell" [attr.colspan]="cols.length">
          <span class="no-data">Keine API-Keys vorhanden</span>
        </td>
      </tr>
    </ng-container>

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef class="truncate" title="API-Key">API-Key</th>
      <td mat-cell class="cell--key truncate" *matCellDef="let item">
        <ng-container *ngIf="item.key.apiKey; else newKey">
          {{ item.key.apiKey }}
        </ng-container>
        <ng-template #newKey>
          <span class="new-key">00000000-0000-0000-0000-000000000000</span>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="truncate" title="Description">Beschreibung</th>
      <td mat-cell class="truncate" *matCellDef="let item" [title]="item.key.description">
        <ng-container *ngIf="item.editMode; else readOnly">
          <mat-form-field>
            <input matInput [(ngModel)]="item.key.description" />
          </mat-form-field>
        </ng-container>
        <ng-template #readOnly>{{ item.key.description }}</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="from">
      <th mat-header-cell *matHeaderCellDef class="truncate" title="Aktiv von">Aktiv von</th>
      <td mat-cell class="truncate" *matCellDef="let item">
        <ng-container *ngIf="!item.editMode">
          {{ item.key.activeFrom | date: 'shortDate' }}
        </ng-container>

        <ng-container *ngIf="item.editMode">
          <mat-form-field>
            <input matInput [matDatepicker]="picker" [(ngModel)]="item.key.activeFrom" />
            <mat-datepicker-toggle matIconSuffix [for]="picker" />
            <mat-datepicker #picker />
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="to">
      <th mat-header-cell *matHeaderCellDef class="truncate" title="Aktive bis">Aktiv bis</th>
      <td mat-cell class="truncate" *matCellDef="let item">
        <ng-container *ngIf="!item.editMode">
          {{ item.key.activeTo | date: 'shortDate' }}
        </ng-container>

        <ng-container *ngIf="item.editMode">
          <mat-form-field>
            <input matInput [matDatepicker]="picker" [(ngModel)]="item.key.activeTo" />
            <mat-datepicker-toggle matIconSuffix [for]="picker" />
            <mat-datepicker #picker />
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef class="truncate" title="Aktive">Aktiv</th>
      <td mat-cell class="truncate" *matCellDef="let item">
        <ng-container
          *ngIf="!item.key.isLocked && (item.key.activeFrom | mpkIsValidDate: item.key.activeTo); else isInactive"
        >
          <mat-icon *ngIf="item.key.apiKey" class="material-icons-outlined icon icon--success" title="Aktiv"
            >check</mat-icon
          >
        </ng-container>
        <ng-template #isInactive>
          <mat-icon class="material-icons-outlined icon icon--warn" title="Inaktiv">block</mat-icon>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="locked">
      <th mat-header-cell *matHeaderCellDef class="truncate" title="Gesperrt">Gesperrt</th>
      <td mat-cell class="truncate" *matCellDef="let item">
        <ng-container *ngIf="item.editMode">
          <div class="icon__checkbox">
            <mat-icon class="material-icons-outlined icon" title="Aktiv">lock</mat-icon>
            <mat-checkbox [(ngModel)]="item.key.isLocked" color="accent" />
          </div>
        </ng-container>
        <ng-container *ngIf="!item.editMode">
          <mat-icon *ngIf="item.key.isLocked" class="material-icons-outlined icon" title="Aktiv">lock</mat-icon>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="truncate" title="Aktionen">Aktionen</th>
      <td mat-cell *matCellDef="let item">
        <ng-container *ngIf="!item.editMode; else editModeActions">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onEdit(item)">
              <mat-icon class="material-icons-outlined">edit</mat-icon>
              <span>Bearbeiten</span>
            </button>
            <button mat-menu-item (click)="onDelete(item)">
              <mat-icon class="material-icons-outlined">delete</mat-icon>
              <span>Löschen</span>
            </button>
          </mat-menu>
        </ng-container>

        <ng-template #editModeActions>
          <button mat-flat-button color="accent" class="save-button" (click)="onSave(item)">
            <mat-icon>check</mat-icon>
            Speichern
          </button>
          <button mat-icon-button (click)="onCancel(item)">
            <mat-icon>clear</mat-icon>
          </button>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="cols"></tr>
    <tr mat-row *matRowDef="let row; columns: cols" [ngClass]="{ 'row--edit-mode': row.editMode }"></tr>
  </table>
</mat-card>
