import { DatePipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';

import { DatePipesModule } from '@core/shared/util';
import { ApiKey, ApiKeyItem } from '@mp/kernel/api-keys/data-access';

import { IsValidDatePipe } from './is-valid-date.pipe';

export type ApiKeyColumnName = 'key' | 'description' | 'from' | 'to' | 'locked' | 'active' | 'actions';

@Component({
  selector: 'mpk-api-key-table',
  standalone: true,
  templateUrl: './api-key-table.component.html',
  styleUrls: ['./api-key-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    NgIf,
    DatePipe,
    ReactiveFormsModule,
    FormsModule,

    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatCheckboxModule,
    MatTableModule,
    MatDatepickerModule,
    MatInputModule,
    MatMenuModule,

    DatePipesModule,
    IsValidDatePipe,
  ],
})
export class ApiKeyTableComponent {
  @HostBinding('class') readonly class = 'mpk-api-key-table';

  readonly cols: ApiKeyColumnName[] = ['key', 'description', 'from', 'to', 'active', 'locked', 'actions'];

  @Input() keys: ApiKeyItem[] = [];

  @Output() readonly saveClicked: EventEmitter<ApiKey> = new EventEmitter<ApiKey>();
  @Output() readonly deleteClicked: EventEmitter<ApiKey> = new EventEmitter<ApiKey>();

  onEdit(key: ApiKeyItem): void {
    key.editMode = true;
  }

  onDelete(key: ApiKeyItem): void {
    this.deleteClicked.emit(key.key);
  }

  onSave(item: ApiKeyItem): void {
    this.saveClicked.emit(item.key);
  }

  onCancel(key: ApiKeyItem): void {
    key.editMode = false;
    if (!key.key.apiKey) {
      this.deleteClicked.emit(key.key);
    }
  }
}
